// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #ffffff;
$gray-100: #F5F7F9;
$gray-200: #F2F2F2;
$gray-300: #E5E5E5;
$gray-400: #E2E2E2;
$gray-500: #D9D9D9;
$gray-600: #C5C5C5;
$gray-700: #B6B6B6;
$gray-800: #898989;
$gray-900: #5F6267;
$gray-1000:#373435;
$black:    #000000;
// scss-docs-end gray-color-variables

// scss-docs-start color-variables
$blue:    #009BB0;
$orange:  #F07D12;
$yellow:  #FFC700;

// scss-docs-start theme-color-variables
$primary:       $blue;
$secondary:     $yellow;

$min-contrast-ratio:   3;


//Create your own map
$custom-colors: (
 "whitesmoke-light" : $gray-100,
 "whitesmoke-dark"  : $gray-200,
 "platinum-light"   : $gray-300,
 "platinum-dark"    : $gray-400,
 "gray-light"       : $gray-500,
 "silver"           : $gray-600,
 "silver-sand"      : $gray-700,
 "trolley-gray"     : $gray-800,
 "gray"             : $gray-900,
 "gray-dark"        : $gray-1000,
 "black"            : $black,
 "orange"           : $orange,
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-negative-margins:     true;

// scss-docs-start spacer-variables-maps
$spacer: 15px;
$spacers: (
  0: 0,
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px,
  6: 6px,
  7: 7px,
  8: 8px,
  9: 9px,
  10: 10px,
  11: 11px,
  12: 12px,
  15: 15px,
  20: 20px,
  25: 25px,
  30: 30px,
  40: 40px,
  50: 50px,
);
// scss-docs-end spacer-variables-maps


// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-600;
$body-color:                $black;


// Links
//
// Style anchor elements.
$link-color:                              $primary;
$link-decoration:                         none;
$link-hover-color:                        $orange;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           15px;


// Paragraphs
//
// Style p element.
$paragraph-margin-bottom:   15px;

// scss-docs-start border-radius-variables
$border-radius:               3px;
$border-radius-circle:        100%;
// scss-docs-end border-radius-variables

$component-active-color:      $primary;
$component-active-bg:         $gray-200;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Source Sans Pro', sans-serif;


// $font-size-base affects the font size of the body text
$font-size-base:              1rem; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .875;
$font-size-lg:                $font-size-base * 1.125;

$font-weight-lighter:         200;
$font-weight-light:           300;
$font-weight-normal:          400;
$font-weight-semibold:        600;
$font-weight-bold:            700;
$font-weight-bolder:          900;

$font-weight-base:            $font-weight-normal;

$line-height-base:            1.25;
$line-height-sm:              1.15;
$line-height-lg:              1.5;

$h1-font-size:                48px;
$h2-font-size:                32px;
$h3-font-size:                24px;
$h4-font-size:                22px;
$h5-font-size:                20px;
$h6-font-size:                18px;
// scss-docs-end font-variables


$hr-opacity:                  .15;

// scss-docs-start headings-variables
$headings-margin-bottom:      $spacer !default;
$headings-font-weight:        700;
// scss-docs-end headings-variables

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         4px;
$input-btn-padding-x:         15px;
$input-btn-font-size:         $font-size-base;
$input-btn-line-height:       $line-height-base;

$input-btn-padding-y-sm:      2px;
$input-btn-padding-x-sm:      15px;
$input-btn-font-size-sm:      $font-size-sm;

$input-btn-padding-y-lg:      6px;
$input-btn-padding-x-lg:      15px;
$input-btn-font-size-lg:      $font-size-lg;

// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables

$btn-font-weight:             $font-weight-semibold;

$btn-box-shadow:              null;
$btn-focus-width:             null;
$btn-focus-box-shadow:        null;
$btn-active-box-shadow:       null;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius;
$btn-border-radius-sm:        $btn-border-radius;
$btn-border-radius-lg:        $btn-border-radius;

// scss-docs-end btn-variables


// Forms

// scss-docs-start form-input-variables

$input-bg:                              $white;
$input-border-color:                    $gray-400;

$input-color:                           $black;
$input-border-color:                    $gray-400;

$input-focus-border-color:               $black;

$input-placeholder-color:               $gray-700;
$input-plaintext-color:                 $body-color;

// scss-docs-start form-label-variables
$form-label-margin-bottom:              5px;
$form-label-font-size:                  $font-size-base;
$form-label-font-weight:                $font-weight-semibold;
$form-label-color:                      $black;
// scss-docs-end form-label-variables

// scss-docs-start form-select-variables
$form-select-indicator-color:       $black;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");

// scss-docs-end form-input-variables

// scss-docs-start form-check-variables

$form-check-input-checked-color:          $white;
$form-check-input-selected-color:         $white;
$form-check-input-checked-bg-color:       $primary;
$form-check-input-checked-border-color:   $black;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg width='10' height='8' viewBox='0 0 10 8' xmlns='http://www.w3.org/2000/svg' fill='none'><path d='M3.332 8c-.154 0-.307-.034-.449-.101-.142-.067-.272-.165-.381-.289l-2.302-2.619c-.129-.146-.201-.345-.201-.551 0-.207.072-.405.201-.551.129-.146.303-.228.485-.228s.356.082.485.228l2.163 2.463 5.503-6.129c.13-.145.305-.225.487-.222.182.002.355.087.482.234.127.148.197.347.195.554-.002.207-.076.404-.206.549l-5.642 6.284c-.219.244-.513.38-.819.379z' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-selected-bg-image:       url("data:image/svg+xml,<svg width='9' height='2' viewBox='0 0 9 2' xmlns='http://www.w3.org/2000/svg' fill='none'><path d='M1 1h7' stroke='#{$form-check-input-selected-color}' stroke-width='6' stroke-linecap='round'/></svg>");
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg width='9' height='9' viewBox='0 0 9 9' xmlns='http://www.w3.org/2000/svg' fill='none'><circle cx='4.016' cy='4.279' r='3.5' fill='#{$form-check-input-checked-bg-color}' stroke='#fff'/></svg>");


$form-check-input-border-radius:          2px;
$form-check-input-focus-border:           $gray-700;

// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-input-bg:              $gray-900;
$form-switch-color:                 $white;
$form-switch-focus-color:           $white;
$form-switch-checked-color:         $white;
// scss-docs-end form-switch-variables

// Navs

// scss-docs-start nav-variables
$nav-link-font-weight:                 $font-weight-semibold;

$nav-tabs-link-active-color:           $link-hover-color;

// Dropdowns
//
// Dropdown menu container and contents.

// scss-docs-start dropdown-variables
$dropdown-min-width:                100px;
$dropdown-padding-y:                10px;
$dropdown-padding-x:                0;
$dropdown-spacer:                   10px;
$dropdown-font-size:                $font-size-sm;

$dropdown-link-color:               $gray-1000;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            transparent;

$dropdown-link-active-color:        $component-active-color;
$dropdown-link-active-bg:           transparent;

$dropdown-item-padding-y:           8px;
$dropdown-item-padding-x:           20px;

$dropdown-header-color:             $black;
$dropdown-header-padding-x:         20px;
$dropdown-header-padding-y:         $dropdown-padding-y;
// fusv-disable
$dropdown-header-padding:           $dropdown-header-padding-y $dropdown-header-padding-x; // Deprecated in v5.2.0
// fusv-enable

// scss-docs-end dropdown-variables

// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y:                     15px !default;
$accordion-padding-x:                     15px !default;
$accordion-bg:                            $gray-100 !default;

$accordion-button-active-bg:              tint-color($component-active-bg, 90%) !default;
$accordion-button-active-color:           shade-color($primary, 10%) !default;

$accordion-button-focus-border-color:     $input-focus-border-color !default;
$accordion-button-focus-box-shadow:       $btn-focus-box-shadow !default;


// scss-docs-end accordion-variables

// Badges
// scss-docs-start badge-variables
$badge-font-size:                   11px;
$badge-font-weight:                 $font-weight-semibold;
$badge-color:                       $white;
$badge-padding-y:                   3px;
$badge-padding-x:                   4px;
$badge-border-radius:               $border-radius;
// scss-docs-end badge-variables

// Modals

// scss-docs-start modal-variables
$modal-inner-padding:               $spacer + 5;
$modal-md:                          568px;

// scss-docs-end modal-variables


// Progress bars

// scss-docs-start progress-variables
$progress-height:                   14px !default;
$progress-bg:                       #fff !default;
$progress-border-radius:            1px !default;
$progress-bar-bg:                   #00587A !default;
// scss-docs-end progress-variables

// Offcanvas

// scss-docs-start offcanvas-variables
$offcanvas-padding-y:               $modal-inner-padding;
$offcanvas-padding-x:               $modal-inner-padding;
$offcanvas-horizontal-width:        700px;
// scss-docs-end offcanvas-variables

