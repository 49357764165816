.media-header-item{
  display: flex;
  align-items: center;
  @media (min-width:1600px) {
      padding:0 15px;                    
  }
  .check-box {
    width: 15px;
    height: 15px;
    border: 1px solid #898989;
    border-radius: 2px;
  }
  
  .dropdown-checkbox {
    margin-left: 10px;
  }
  
  .dropdown-menu {
    margin-left: -25px;
    margin-top: 2px;
    background-color: white;
  }
  
  .delete-btn {
    margin-left: 0px;
  }
  
  .delete-btn:hover {
    cursor: pointer;
  }
}