body {
    background-color: $body-bg;
    overflow: hidden;
}
body .topbar + .main-container, 
body .topbar + main {
    // padding-top: 54px;
}
#root {
    position: relative;
}
.whole-container {
    display: flex;
}
.main-container {
    display: flex;
}
.main-content {
    width: 100%;
    margin: 0 0px 5px 5px
}

//Avatar
.avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    width: 30px;
    height: 30px;
    > img{
        max-width:100%;
    }
}
.avatar-md{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.avatar-lg{
    width: 60px;
    height: 60px;
}
.avatar-alphabet{
    color:#000;
    font-size:24px;
    font-weight:600;
    background-color: $green-100;
}

// Topbar
.topbar {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right:25px;
    height:54px;
    background: #DFE5E6;  
    transition:margin-left .3s ease;
    z-index:1001;
}
.topbar-left {
    display: flex;
    align-items: center;
    max-width:600px;
    width:100%;
}
.topbar-right {
    display: flex;
    align-items: center;
}
.topbar-search{
    width:100%;
    .form-control{
        border-radius: 30px;
    }
}
.topbar-btns {
    list-style:none;
    padding-left:0;
    display:flex;
    align-items: center;
    .dropdown{
        margin:0;
    }
    .topbar-btn {
        padding: 0 15px;
        color:#000;
        display: inline-block;
        border: none;
        font-size: 20px;
        line-height: 1;
        &::after{
            display:none;         
        }
        .badge{
            display: none;
            position: absolute;
            top: -8px;
            right: 4px;
            width: 16px;
            height: 16px;
            border-radius:$border-radius-circle;
        }
        &.has-new {
            .badge{
                display:flex;
                align-items: center;
                justify-content: center;
            }
        }
        > .avatar {
            &:hover {
                color: #F07D12;
            }
        }
    }
}
.topbar-divider{
    border-left: 1px solid #8A9194;
    height: 40px;
    align-self: center;
    margin: 0 30px;
}
.topbar-link{
    margin:0;
}
.topbar-dropdown{
    &.show{
        .topbar-btn {
            color:$secondary;
        }
    }
}


// Sidebar
.sidebar {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    width: 54px;
    height: 100vh;
    background-color: #00587A;
    display: flex;
    flex-direction: column;
    transform: translateZ(0);
    transition: .3s ease;
    backface-visibility: hidden; 
    z-index:1002;
}
// .sidebar ~ .topbar, .sidebar ~ main {
//     margin-left: 54px;
// }
.sidebar-navigation {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}
.sidebar-header {
    padding:0 6px;
    color:#fff;
    .logo-icon {
        display: flex;
        justify-content: left;
        padding: 8px 0;
        margin-bottom: 10px;
        border-bottom: 1px solid #F2F2F2;
        width: 100%;
    }
}


// Menu
.menu {
    list-style:none;
    padding-left:0;
    margin-bottom:30px
}

.menu-down {
    margin-bottom: 120px;
}

.menu-item {
    transition:opacity .2s linear;
    display: flex;
    align-items: center;
    + .menu-item {
        margin-top:25px;
    }

}
.menu-link {
    padding:0 12px;    
    transition:.2s linear;
    color: #F2F2F2;
    border:none;
    background-color: transparent;
    display:flex;
    align-items: center;

    &:hover, &.active {
        .icon, .text {
            transition: .2s ease-in-out;
            color:#F07D12;
        }
    }
    .icon {
        + .text{
            position: absolute;
            margin-left:50px;
        }
    }
    .text{
        font-size:16px;
        line-height:20px;
        font-weight:600;       
        opacity:0;
        visibility: hidden;
        transition: opacity .2s ease-in-out;
        height:24px;
    }
    .icon-arrow-down {
        margin-left: 135px;
        font-weight: 50;
    }
    
}


//Sidebar Expanded
.sidebar-expanded{
    .sidebar {
        width:250px;
        .menu .menu-link {
            .text {
                opacity:1;
                visibility:visible;
            }
        }
    }
}



// Media
.media{
    display: flex; 
    justify-content: space-between;
    align-items: center; 
    padding:10px 5px; 
    transition:background-color .2s linear; 
    color:#000;
}
.media>* {margin: 0 5px;}
.media-body{flex-grow: 1;}
.media-list-header{
    border-bottom:1px solid #ebebeb;
    background-color:#fcfdfe

}
.media-list-header-left{display: flex; align-items: center;}
.media-list-header-right{display: flex; align-items: center;}
.media-list-footer{border-top:1px solid #ebebeb;background-color:#fcfdfe}
.media-list-divided {
    .media-list-body, .dropdown-body{
        > .media {
            border-bottom:1px solid #d9d9d9;
        }
    }
}
.media-list-hover{
    .media-list-body{
        > .media{
            &.active, &:hover {
                background-color:#f2f2f2
            }
        }
    }
}



// Section Tntegration
.integration-content{
    text-align: center;
    padding:120px 0;
    background-color:#fff;
    border-radius:3px 0 0;
    margin-top:5px;
    min-height: calc(100vh - 60px);
    .container{
        max-width: 650px;
        width: 100%;
    }
    .title{
        margin-bottom:35px;
    }
    .lead, .sub-lead{
        font-weight:600;
        color: #000;
    }
    .lead{
        font-size:24px;
        line-height:34px;
        margin-bottom:85px;
    }
    .sub-lead{
        font-size:22px;
        line-height:28px;
        margin-bottom:40px;
    }
    .subtitle{
        font-weight:600;
        margin-bottom:90px;
    }
}
.integration-body{
    margin-bottom:90px;
}
.connectbox{
    margin:0 auto;
    display:flex;
    align-items:center;
    justify-content: space-between;    
    .connectbox-item{ 
        width: 192px;
        // flex: 1 0;
        border: 1px solid #d9d9d9;
        // padding: 20px;
        text-align: center;
        // margin: 0 20px;
        min-height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.overlay{
            position: relative;
            text-align:center;
            &:after{
              content:'';  
              position:absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
              background-color:rgba(0,0,0,0.7);
            }
            .overlay-text{
                position: absolute;
                z-index:1;
                color:#fff;
                font-weight:600;
                top:50%;
                left:50%;
                transform: translate(-50%, -50%);
                text-align: center;
            }
        }
    }
}

.list-group-check{
    text-align: left;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 20px;
    .list-group-check-item{
        .form-check{
            margin-bottom:0;
        }
        + .list-group-check-item{
            margin-top:20px;
        }
        .list-group-check{
            padding-left: 25px;
        }
        > .list-group-check{
            margin-top:20px;
            margin-bottom:0;
        }
    }
    .list-group-check-item{
        position: relative;
        display: block;
    }
   
}

.list-group-inline{
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    padding-left: 0;
    .list-group-inline-item{        
        padding: 0 10px;
        
    }
}


//Inbox
.aside {
    background-color:#fff;
    box-shadow:1px 1px 5px rgba(0,0,0,0.04);
    // position:fixed;
    // top:0;
    // bottom:0;
    min-width: 310px;
    padding-top:20px;
    transform:translateZ(0);
    transition:.3s ease;
    backface-visibility:hidden;
}

.aside ~ .main-content{
    // margin-left:310px
}

.aside-header{
    padding:15px 30px;
    + .aside-body {
        padding-top:0;
    }
}
.aside-body {
    padding:15px 30px;
    height:100%;
    .form-group{
        .form-select{
            border: none;
            border-bottom: 1px solid #d9d9d9;
            background-position: right 0 center;
            padding-left:0;
            font-weight:600;
        }
    }
}

.aside-title {
    color:#000;
    font-size:16px;
    line-height:20px;
    text-transform:uppercase;
    font-weight:600;
}

.nav.flex-column {
    .nav-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition:.2s linear;
        .badge{
            font-size:16px;
            color:#5F6267;
        }
        + .nav-item {
            margin-top:4px;
        }
        .nav-link {
            color:#000;
            padding:5px 0 5px 10px;
            position: relative;
            flex: 1 1;
            &:before{
                content: '';
                position: absolute;
                left:0;
                width:3px;
                height:30px;
                background-color: #d9d9d9;
                transition: all 0.5s ease-in-out;
                top: 50%;
                transform:translateY(-50%);
            }
        }
        &.active {
            .nav-link{
                font-weight:600;
                &:before{
                    background-color:#009BB0;
                }
            }
        }
        &:hover {
            .nav-link {
                &:before{
                    background-color:#000;
                }
            }
        }
    }
}



// Section Inbox
.section-email{
    height:100%;
}

.media-email{
    // height:100%;
    > .media-list-header{
        background-color: #F5F7F9;
        margin:0 0 5px 0;
        padding:12px 30px;
        flex-flow: wrap;
        .dropdown-icon{
            display: flex;
        }
        .media-header-left{
            display: flex;
            align-items: center;
            margin:0 -10px;
            @media (min-width:1600px) {
                margin:0 -15px;
            }
            .media-header-item{
                display: flex;
                align-items: center;
                padding:0 10px;
                @media (min-width:1600px) {
                    padding:0 15px;                    
                }
                .text {
                    color: #5F6267;
                    font-family: Source Sans Pro;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal; 
                }
                &:hover {
                    .icon, .text {
                        transition: .2s ease-in-out;
                        color: #F07D12;
                    }
                }
            }            
            .btn-group{
                .btn{
                    + .btn{
                        margin-left:20px;
                        @media (min-width:1600px) {
                            margin-left:30px;
                        }
                    }    
                }
            }
            // .dropdown-checkbox{
            //     .btn{
            //         + .btn{
            //             margin-left:0;
            //         }    
            //     }
            // }
        }

        .media-header-right{
            display: flex;
            align-items: center;
            margin:0 -10px;
            @media (min-width:1600px) {
                margin:0 15px;
            }
            .media-header-item{
                display: flex;
                align-items: center;
                padding:0 10px;
                @media (min-width:1600px) {
                    padding:0 15px;                    
                }
                
                &:hover {
                    .icon, .text {
                        transition: .2s ease-in-out;
                        color: #F07D12;
                    }
                }
            }
            .pagination-nav{
                color: #5F6267;
                .pagination-count{
                    font-size:12px;
                    line-height:16px;
                }
                .page-link{
                    padding:0 5px;
                    border:none;
                    background-color: transparent;
                    font-size:10px;
                    color: #898989;
                    &:hover{
                        .icon-arrow-left, .icon-arrow-right {
                            color: #000;
                        }
                    }
                }
            }
        }
    }    
    .media-title-header{
        padding:0 10px 20px 10px;
        border-bottom:1px solid #d9d9d9;
        > .title{
            font-size:16px;
            font-weight: 700;
            color:#000;
        }
    }
    > .media-list-body{
        height: calc(100vh - 114px);
        margin-bottom: 20px;
        border-radius:3px 0 0;
        background-color:#fff;
        padding: 20px 30px 20px 20px;
    }
}

.media{
    width: 100%;
    
    .media-subject, .media-file, .media-msz, .media-dayTime, .media-mail{
        font-size:13px;
        line-height: 20px;
        color: $gray-900;
    }          
    .media-dayTime{
        white-space: nowrap;
        color: $black;
    }
    .media-subject{
        width: 30%;
        font-weight: $font-weight-semibold;
        color: $black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        
        .unread-part {
          font-weight: 700;
        }
    }
    .media-name{
        display: flex;
        align-items: center;
        font-size:14px;
        color: $black;
        > strong{
            + .btn-toolbar{
                margin-left:20px;
            }
        }
    }  
    .custom-checkbox{
        width: 20%;

        .form-check-label{
            font-size:13px;
            line-height: 20px;
            color: #000;
            vertical-align: middle;
            
            .unread-part {
              font-weight: 700;
            }
        }
    }    
    .media-dayTime, .media-body{
        color: #000;
    }    
        
    &.checked{
        background: #DFE5E6;
    }
    &.unread, &.draft, &.spam{
        background: #F2F2F2;
    }
}

.media-box{
    .form-check{
        height: 20px;
        .form-check-label{
            max-width: 170px;
            width: 100%;
        }
    } 
}

.media-draft, .media-spam{
    .custom-checkbox{
        // max-width: 145px;
        width:20%;
        .form-check-label{
            display: block;
        }
    }
    .media-body{
        .media-subject{
            + .media-msz{
                margin-left:15px;
            }
        }
    }
}

.media-thread{
    padding:0;
    width:100%;
    > .media-left{
        + .media-body{
            margin-left:30px;
        }
    }
    .media-subject-header{
        align-items: center;
        margin-bottom:10px;
        .media-subject{
            font-size:14px;
            margin-bottom: 5px;
        }
    }
}

.media-schedule{
    .media-body{
        .form-check{
            + .media-mail{
                margin-left:25px;
            }        
        }
    }
}

.media-editor{
    padding: 0 0 0 15px;
    align-items: flex-start;
    border-top: 1px solid #d9d9d9;
    padding-top: 20px;
    > .media-left{
        + .media-body{
            margin-left:30px;
        }
    }
    > .media-body{
        .btn-toolbar{
            margin-top:10px;
            margin-bottom:10px;
            .btn{
                border-color:#C5C5C5;
                &:hover {
                    border-color: #E0E0E0;
                }
            }
        }
    }
}

.media-info {
    display: flex;
    gap: 12px;
    width: 85px;
    justify-content: right;
    align-items: center;
}

.media-files{
    padding:0;
    font-size: 14px;
    line-height: 18px;    
    cursor: pointer;
    > .media-icon{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        .icon{
            display: block;
            text-align: center;
            font-size: 20px;
            color: #5F6267;
        }
        .file-type{
            font-size:12px;
            line-height: 14px;
            color: #5F6267;
        }
    }
    > .media-body{
        overflow: hidden;
        .file-name{
            font-size: 14px;
            line-height: 18px;
            color:#000;
            &:hover, &:focus{
                color:$primary;
            }
        }
        .file-size{
            font-size: 12px;
            color: #898989;
            line-height: 14px;
            display: inline-block;
        }
    }   
    > .media-action{
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
            .icon {
                color: #F07D12;
            }
        }
        > .progress{
            width:100px;
            border: 1px solid #00587A;
            + .action{
                margin-left:20px;
            }
        }    
        > .action{
            &:hover, &:focus{
                .icon {
                    color: #00587A;
                }
            }
        }
        
    }
}

.media-option{
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    width: auto;
    margin:0 -15px;

    .media-option-item{
        padding:0 15px;
    }

    .reply-option-button:hover {
        &:hover {
            .icon-button {
                color: #F07D12;
            }
        }
    }
    .reply-option-section {
        position: absolute;    
        top: 25px;    
        right: 15px;
        width: 200px;
        height: 175px;
        flex-shrink: 0;
        border-radius: 0px 0px 3px 3px;
        border: 1px solid #D9D9D9;
        background: #FFF;
        z-index: 999;

        display: flex;
        flex-direction: column;
        padding: 10px 0px 10px 0px;

        a, div {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px 0px 10px 20px;

            &:hover {                
                background-color: #EEEEEE;
            }
        }
        
        span {
            color: #373435;
            font-family: Source Sans Pro;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    
}

.list-group-media{  
    margin-bottom:20px;
    .list-group-item{
        .media{
            background-color:#F2F2F2;
            border-radius:3px;
            padding:8px 15px;
        }
        + .list-group-item{
            margin-top:10px;
        }
    }
}



//dropdown-link/icon
.dropdown-header{
    .badge{
        font-size:16px;
        color:#898989;
    }
}
.dropdown-link, .dropdown-icon{
    .dropdown-toggle{
        border:none;
        vertical-align: top;
        &:focus{
            box-shadow: none;
        }
        &::after{
            display:none;
        }
    }
}
.dropdown-add{
    .dropdown-menu{
        min-width:200px;
    }
    &.show{
        .dropdown-toggle{
            color:$orange;
        }
    }
}
.dropdown-notify{
    .dropdown-menu{
        min-width:500px;
    }
    .dropdown-header{
        border-bottom: 1px solid #d9d9d9;
        margin: 0 20px;
        padding: 5px 0;
        text-transform: uppercase;
    }
    .dropdown-body{
        max-height:450px;
        height:100%;
        margin:0 20px;
    }
    .media{
        padding: 20px 0;
        color:$primary;
        white-space: inherit;
        .media-left{
            margin-left:0;
        }
        .media-body{
            color:#000;
            > .flexbox{
                p{
                    margin-bottom:0;
                    min-height: 30px;
                }
                span{
                    small, .close{
                        font-size:13px;
                        line-height:16px;
                        color: #898989;
                    }
                    small{
                        white-space: nowrap;
                        + i{
                            margin-left:20px;
                        }
                    }
                    .close{
                        font-size:10px;
                        opacity:0;
                        visibility: hidden;
                        transition: opacity 0.5s ease-in-out;
                        &:hover, &:focus{
                            color:#000;
                        }
                    }
                }
            }
            b{
                font-size:13px;
                line-height:16px;
                font-weight:600;
                color: #000;
            }
        }
        &.active, &:active,
        &:hover, &:focus{
            color:#000;
            .media-body{
                > .flexbox{
                    .close{
                        opacity:1;
                        visibility:visible;
                    }
                }
            }
        }
    }
}
.dropdown-placehoder{
    .text-black {
        font-weight: 400;
    }
    .dropdown-menu{
        min-width:312px;
        .dropdown-item{
            padding-left: 30px;
            padding-right: 30px;
        }
    }
    .dropdown-body{
        max-height: 215px;
    }
}
.dropdown-template{
    .dropdown-menu{
        min-width:455px;
        .dropdown-item, .dropdown-item-text{
            padding-left: 30px;
            padding-right: 30px;
        }
        .dropdown-divider{
            margin:5px 30px;
        }
    }
    .dropdown-body{
        max-height: 215px;
    }
}
.dropdown-split{
    .dropdown-menu{
        min-width:130px;
        .dropdown-item{
            color:$primary;
            display: flex;
            align-items: center;
            .icon{
                font-size:20px;
                color:#5F6267;
                + .text{
                    margin-left:15px;
                }
            }
            &:hover, &:focus{
                color:#5F6267;
            }
        }
    }
}

.dropdown-reply{
    .dropdown-menu{
        min-width:200px;
        .dropdown-item{
            font-weight:$font-weight-semibold;
            .icon, span{
                display: inline-block;
                vertical-align: middle;
            }
            .icon{
                width: 20px;
                display: inline-block;
                text-align: center;
                + span{
                    margin-left:10px;
                }
            }
        }
    }
}

.split-divider{
    position: relative;
    &:before{
        position: absolute;
        content: '';
        left:0;
        top:50%;
        transform: translateY(-50%);
        width:1px;
        height:65%;
        background-color:#373435;
    }
}

// Button
.btn-toolbar{
    align-items: center;
    margin:0 -15px;
    > .btn, > .form-check{
        margin:0 15px;
    }
    .form-check{
      margin-bottom:0;
    }
    &.md{
        // margin:0 -8px;
        margin-left:8px;
        > .btn{
            margin:0 8px;
        }
    }
}

//accounts
.accounts{
    figure{
        margin-bottom:40px;
    }
    .btn-toolbar{
        justify-content: center;
        margin: 15px;
        > .btn{
            flex: 1 0;
            margin: 0 5px;
        }
    }
}

//Checkbox
.form-check-input{
    border-radius: 2px;
    border: 1px solid #898989; 
    width: 15px;
    height: 15px;
    &:checked[type=checkbox]{
        background-size: auto;
    }
    &:checked[type=radio]{
        background-color: $primary;
        border-color: #000;
        background-position: 1px 0px;
    }
    &.selected{
        background-image: escape-svg($form-check-input-selected-bg-image) !important;
        background-color: $form-check-input-checked-bg-color;
        border-color: $form-check-input-checked-border-color;
        background-size: auto;
    }
}

.form-switch {
    .form-check-label{
        font-size: $form-label-font-size;
        font-weight: $form-label-font-weight;
        color: $form-label-color;
    }
    .form-check-input{
        background-color:$form-switch-input-bg;
        border-color:$form-switch-input-bg;
        &:checked{
            background-color:$form-check-input-checked-bg-color;
            border-color:$form-check-input-checked-bg-color;
        }
    }

}
//search-box
.search-box{
    position: relative;
    min-width: 400px;
    @media (min-width:1600px) {
        min-width: 600px;
    }
    .lookup {
        justify-content: flex-end;
    }
    .search-result{
        display: none;
    }
    &.isShowResult{
        .search-result{
            display: block;
        }
    }

}
.search-list{
    display: block;
}
.search-filter{
    display: block;
}
.search-result{
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% + 4px);
    border:1px solid #C5C5C5;
    background-color:#fff;
    padding:20px;
    border-radius:3px;
    &.isShowSearchFilter{
        .search-list{
            display: none;
        }
        .search-filter{
            display: block;
        }
    }
}
.search-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #d9d9d9;
    padding-bottom: 10px;
}
.open-filter, .close-filter{
    border:none;
    background-color: transparent;
    padding:0;
    color:#000;
    &:hover, &:focus{
        color:$primary;
    }
}
.open-filter{
    font-size:20px;
    &:hover {
        .icon-filter {
            color: #009BB0
        }
    }
}
.close-filter{
    font-size:14px;
    &:hover {
        .icon-close {
            color: #009BB0
        }
    }
}
.search-body{
    .form-group{
        margin-bottom:30px;
        
        > .form-check ~ .form-check {
            margin-top: 20px;
        }
    }
}

.media-search{
    .media{     
        .media-list-body{
            max-height:290px;
        }
        .media-body{
            line-break: anywhere;
            color:#5F6267;
            font-size:13px;
            strong{
                font-size:14px;
            }
            p{
                margin-bottom:0;
                width:100%;
                max-width:230px;
                @media (min-width:1600px) {
                    max-width:415px;
                }
            }
            b{
                font-weight: 600;
                color: #000;
            }
            small{
                font-size:13px;
                color:#000;
                font-weight: 600;
                white-space: nowrap;
            }
        } 
    }
}

.list-tag{
    display: flex;
    align-items: center;
    justify-content: center;
    margin:0 -5px;
}
.tag-item{
    padding:0 5px;
}
.tag{
    cursor: pointer;
    background-color: #f2f2f2;
    color: #000;
    line-height: 18px;
    display: inline-block;
    transition: all 0.6s ease-in-out;
    padding: 8px;
    font-size: 12px;
    @media (min-width:1600px) {
        padding: 8px 15px;
        font-size: 13px;
    }
    &:hover{
        background-color:#E2E2E2;        
    }
}


//Compose
.compose{
    background-color:#fff;        
    padding: 20px 30px;
    height: calc(100vh - 54px) !important;
}
.reply-compose{
    background-color:#fff;
    margin-left: -5px;
}
.compose-header{
    margin-bottom:20px;
    .input-group{
        margin-bottom:5px;
        .btn{
            border-radius:0;
        }
        .input-group-text{
            background-color: transparent;
            &:first-child{                
                min-width: 80px;
                padding: 0;
                font-weight:600;
                color:#000;
                border: none;

            }
        }

    }
}
.compose-body{
    margin-bottom:20px;
}

.compose-to{
    .input-group-text{
        border-left:0;
        + .input-group-text{
            padding-left:0;
        }
    }
}

//Editor
.note-editor{
    position: relative;
    
}
.note-editor-view{
    > p{
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        color: #000;
    }   
}

.note-editer-area{   
    margin-bottom:20px;

    > img{
        max-width:100%;
        width:100%;
        max-height:650px;
        height:100%;
    }
    &.sm{
        max-height:250px;
    }
    .list-group-media{
        margin:20px;
    }

    //TinyMCE style customize    
    .tox-tinymce {
        border: none;
        border-radius: 0px;
    }
    .tox:not(.tox-tinymce-inline) .tox-editor-header {
        background-color: #F2F2F2;
        box-shadow: none;        
    }
    .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
        background-color: #F2F2F2;
    }
    .tox .tox-edit-area__iframe {
        margin-top: 10px;
        background-color: #FAFAFA;
        border-radius: 3px;
        border: 1px solid #E2E2E2;
    }
    .tox .tox-statusbar {
        display: none !important;
    }
    //attachments section
    .attachments-container {
        padding: 20px 20px 0 20px;
        background-color: #FAFAFA;
        border-radius: 0 0 3px 3px;
        border: 1px solid #E2E2E2;
        border-top: none;
        .attachments-section {
            border: 1px dashed #898989;
            background: #E5E5E5;
        }
        .explain-section {
            color: #3c3c3c;
            text-align: center;
        }
    }

}

.note-editer-area-simple{   
    margin-bottom:20px;
    &.sm{
        max-height:250px;
    }
    .list-group-media{
        margin:20px;
    }

    .input-reply-section {
        position: relative;
        display: flex;
        gap: 10px;
        padding: 25px 20px 0 20px;
        background-color: #FAFAFA;
        border-radius: 3px 3px 0 0;
        border-left: 1px solid #E2E2E2;
        border-right: 1px solid #E2E2E2;
        border-top: 1px solid #E2E2E2;

        .reply-dropdown-button {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 5px;
        }

        .reply-dropdown-button:hover {
            background-color: #F0F0F0;
            border-radius: 10%;
            cursor: pointer;
        }
        
        .reply-option-section {
            position: absolute;    
            top: 50px;    
            left: -85px;
            width: 160px;
            height: 135px;
            flex-shrink: 0;
            border-radius: 0px 0px 3px 3px;
            border: 1px solid #D9D9D9;
            background: #FFF;
            z-index: 999;

            display: flex;
            flex-direction: column;
            padding: 10px 0px 10px 0px;

            div {
                display: flex;
                align-items: center;
                gap: 10px;
                padding: 10px 0px 10px 20px;

                &:hover {                
                    background-color: #EEEEEE;
                    cursor: pointer;
                }
            }
            
            span {
                color: #373435;
                font-family: Source Sans Pro;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }

        .input-reply-to {
            outline: none;
            border: none;
            background-color: #FAFAFA;
            width: 100%;
        }
    }
    //TinyMCE style customize    
    .tox-tinymce {
        border: none;
        border-radius: 0%;
        padding: 0;
    }
    .tox:not(.tox-tinymce-inline) .tox-editor-header {
        background-color: #FAFAFA;
        padding: 0 15px 0 15px;
        border-top: none !important;
        border-bottom: none !important;
        border-left: 1px solid #e3e3e3;
        border-right: 1px solid #e3e3e3;
    }
    .tox .tox-toolbar, .tox .tox-toolbar__overflow, .tox .tox-toolbar__primary {
        background-color: #F2F2F2;
    }
    .tox .tox-edit-area__iframe {
        background-color: #FAFAFA;
        border-left: 1px solid #E2E2E2;
        border-right: 1px solid #E2E2E2;
    }
    .tox .tox-statusbar {
        display: none !important;
    }
    //attachments section
    .attachments-container {
        padding: 10px 15px 0 15px;
        background-color: #FAFAFA;
        border-radius: 0 0 3px 3px;
        border: 1px solid #E2E2E2;
        border-top: none;
        .attachments-section {
            border: 1px dashed #898989;
            background: #E5E5E5;
        }
        .explain-section {
            color: #3c3c3c;
            text-align: center;
        }
    }

}

.note-editor-action{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.note-editor-action-left, 
.note-editor-action-right{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin:0 -8px;
    .note-editor-action-item{
        padding:0 8px;
        display: flex;
        align-items: center;
        &:hover {
            .icon {
                color: #F07D12
            }
        }
    }
    .form-check{
        .form-check-label{
            display: flex;
            align-items: center;
            i{
                margin-left:10px;
            }
        }
    }
}


// Offcanvas
.offcanvas-footer{
    padding: $offcanvas-padding-x $offcanvas-padding-y;
}
.offcanvas-default{
    .offcanvas-header{
        border-bottom: 1px solid #D9D9D9;
    }
    .offcanvas-footer{
        border-top: 1px solid #D9D9D9;
    }
}

//Task
.task-form{
    display: flex;
    align-items:center;
    justify-content: center;
    padding-top:15px;
    border:1px solid #D9D9D9;
    border-top: none;
    height:100%;
}
.task-form-inner{
    width:100%;
}
.list-button{
    display: flex;
    align-items: center;    
    margin: 20px -10px 25px -10px;
    .list-item-button{
        padding:0 10px;
        > .btn{
            font-weight:$font-weight-semibold;
        }
        + .list-item-button{
            border-left:1px solid #C5C5C5;
        }
    }
}

//Nav Tabs
.nav-bordered{
    position: relative;
    margin-bottom:20px;
    border-bottom:4px solid #E5E5E5;
    .nav-item{
        .nav-link{
            position: relative;
            &:before{
              content: '';  
              position: absolute;
              bottom:-4px;
              left:0;
              right:0;
              height:4px;
              background-color:transparent;
            }
            &.active{
                color:#F07D12;
                &:before{
                    background-color:#F07D12;
                }
            }
        }
    }

}

//Accordion
.accordion-default{
    padding-bottom:20px;
    .accordion-item{
        background-color: transparent;
        border: none;
        + .accordion-item{
            margin-top:20px;
        }
    }
    .accordion-header{        
        .accordion-button{
            &:after{display: none;}
        }
    }
    .accordion-body{
        padding-left:110px;
        .list-group-media{
            .list-group-item {
                .media{
                    background-color:transparent;
                    border-radius: 0;
                    padding:0;
                }
            }
        }

        
    }
    .accordion-button{
        &:not(.collapsed){
            box-shadow: none;
        }
    }
}

//Modal
.modal-subtitle{
    color:#000;
    font-size:12px;
}


//Setings
.setings{
    padding:0 20px 0 50px;
    .setings-items{
        + .setings-items{
            margin-top:20px;
            padding-top:20px;
            border-top:1px solid #D9D9D9;
        }
        .list-button {
            .list-item-button {
                + .list-item-button{
                    border-left:none;
                }                
                &:hover {
                    .icon, .text {
                        transition: .2s ease-in-out;
                        color:#F07D12;
                    }
                }
                .disconnect-btn {
                    margin-left: 3px;
                    border: none;
                    background-color: transparent;
                    color: var(--link, #009BB0);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal; 

                    &:hover {
                        color: #F07D12;
                    }
                }
            }
        }
    }
}

//profile Dropdown
.dropdown-profile{
    .dropdown-header{
        background-color:#F5F7F9;
        border-bottom:1px solid #D9D9D9;
        padding-top:20px;
        padding-bottom:20px;
    }
    .dropdown-menu{
        padding:0;
        min-width: 300px;
        @media (min-width:1200px) {
            min-width: 400px;
        }
        @media (min-width:1600px) {
            min-width: 500px;
        }
    }                                              
    .dropdown-body{
        .dropdown-item-text{
            padding:20px;
        }
        .dropdown-divider{
            margin:0 20px;
        }
        .media-body{
            b, p{
                display: block;
                font-size:16px;
                color:#000;
                margin-bottom:8px;
            }
            b, .btn-link{
                font-size:16px;
                font-weight:$font-weight-semibold;
            }

        }
    }
}

.profile-avatar{
    display: flex;
    align-items: center;
    margin:0 -15px;
    .avatar {
        position: relative;
        // margin:0 5px;
        .icon{
            position: absolute;
            bottom: 0;
            right: 0px;
            width: 18px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }    
}
.profile-content{ 
    margin:0 5px;
    .profile-name, .profile-position{
        display: flex;
        align-items: center;
        .text{
            font-size:14px;
            line-height:18px;
            font-weight:400;
            color: #000;
        }
        .divider-line{
            margin:0 15px;
            height:16px;
        }
    }
    .profile-name{
        margin-bottom:10px;
        .text{
            font-weight:600;
            font-size:18px;
            line-height:34px;
        }
        .icon{
            color:#5F6267;
            &:hover, &:focus{
                color:#000;
            }
        }
    }
}

.icon-arrow-down {
    margin-top: -2px;
    margin-right: 5px;
}

.title-part {
    .title-text-truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .unread-part {
        font-weight: 700;
      }
    }
  }
  
  .content-data {
    display: flex;
    padding-left: 20px;
    width: 1px;
    gap: 10px;
    
    .content-title {
      // min-width: fit-content;
      display: flex;
      align-items: center;
    }
    .content-text {
        color: #5F6267;
        width: 100%;
        font-size: 13px;
        font-weight: 400;
    }
    .sent-email-state-section{
        display: flex;
        gap: 16px;
        color: #898989;
        font-family: Source Sans Pro;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  }
  //Delete Confirm Modal
  .modal-content {
    background-color: white;
    border-radius: 3px;

    .modal-title-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .modal-title-text {
            color: #000;
            font-family: Source Sans Pro;
            font-size: 22px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .modal-close-btn {
            &:hover {
                .icon-btn {
                    color: #009BB0;
                }
            }
        }
    }

    .modal-content-section {
        margin-top: 16px;
        color: #000;
        font-family: Source Sans Pro;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .model-button-section {
        display: flex;
        justify-content: end;
        margin-top: 28px;
        gap: 20px;
        
        .modal-button {
            border: none;
            background-color: transparent;
            color: #000;
            font-family: Source Sans Pro;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            &:hover {
                color: #009BB0;
            }
        }
        
        .modal-yes-button {
            display: flex;
            padding: 6px 22px;
            align-items: flex-start;
            gap: 10px;
            border-radius: 3px;
            background: #FFC700;
        }
    }
  }