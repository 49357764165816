@font-face {
  font-family: 'icomoon';
  src:
    url('../fonts/icomoon.ttf?blhl8t') format('truetype'),
    url('../fonts/icomoon.woff?blhl8t') format('woff'),
    url('../fonts/icomoon.svg?blhl8t#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lock:before {
  content: "\e931";
}
.icon-email-click:before {
  content: "\e900";
}
.icon-email-open:before {
  content: "\e901";
}
.icon-schedule-cancel:before {
  content: "\e902";
}
.icon-contacts:before {
  content: "\e90a";
}
.icon-dashboard:before {
  content: "\e90b";
}
.icon-email:before {
  content: "\e90d";
}
.icon-expand-menu:before {
  content: "\e90e";
}
.icon-leads:before {
  content: "\e90f";
}
.icon-orders:before {
  content: "\e910";
}
.icon-reports:before {
  content: "\e911";
}
.icon-settings:before {
  content: "\e912";
}
.icon-tasks:before {
  content: "\e913";
}
.icon-hrt-menu:before {
  content: "\e914";
}
.icon-account:before {
  content: "\e915";
}
.icon-notification:before {
  content: "\e916";
}
.icon-add-circle:before {
  content: "\e933";
}
.icon-fill-arrow-down:before {
  content: "\e92e";
}
.icon-fill-arrow-up:before {
  content: "\e934";
}
.icon-attachment:before {
  content: "\e938";
}
.icon-calendar:before {
  content: "\e939";
}
.icon-camera:before {
  content: "\e93a";
}
.icon-close:before {
  content: "\e93b";
}
.icon-delete:before {
  content: "\e93c";
}
.icon-download:before {
  content: "\e917";
}
.icon-file:before {
  content: "\e919";
}
.icon-filter:before {
  content: "\e91a";
}
.icon-logout:before {
  content: "\e925";
}
.icon-mention:before {
  content: "\e926";
}
.icon-new-document:before {
  content: "\e927";
}
.icon-clock:before {
  content: "\e928";
}
.icon-question-cirlcle:before {
  content: "\e929";
}
.icon-refresh:before {
  content: "\e92a";
}
.icon-search:before {
  content: "\e92c";
}
.icon-send-later:before {
  content: "\e92d";
}
.icon-time-zone:before {
  content: "\e92f";
}
.icon-ver-menu:before {
  content: "\e930";
}
.icon-company:before {
  content: "\e932";
}
.icon-arrow-left:before {
  content: "\e936";
}
.icon-arrow-right:before {
  content: "\e937";
}
.icon-arrow-top:before {
  content: "\e935";
}
.icon-arrow-down:before {
  content: "\e93d";
}
.icon-forward:before {
  content: "\e91b";
}
.icon-reply:before {
  content: "\e92b";
}
.icon-plus:before {
  content: "\e907";
}
.icon-exclamation:before {
  content: "\e908";
}
.icon-pencil:before {
  content: "\e90c";
}
.icon-expanded-menu:before {
  content: "\e903";
}