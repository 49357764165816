textarea{
    resize: none;
}
html, body{
    height: 100%;
}
b, strong {
    font-weight: 700;
}

input[type="date"]::-webkit-calendar-picker-indicator, 
input[type="time"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}