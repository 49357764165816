// Input
.input-icon {
  position: relative;
  .icon {
    left: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(1.5em + 1.3rem + 2px);
    i {
      line-height: 0;
      color: $gray-900;
    }
  }
  .form-control {
    padding-left: calc(1.5em + 1.3rem + 2px);
  }

  &.input-icon-right .icon {
    right: 0;
    left: auto;
  }
  &.input-icon-right .form-control {
    padding-left: 1rem;
    padding-right: calc(1.5em + 1.3rem + 2px);
  }
}

/*Search*/
.lookup {
  position:relative;
  display: flex;
  align-items: center;
  
  & .form-control {    
      transition:.5s;
      border-radius:30px;
      &:focus {
        border-color:#000;
      }
  }
  &:hover{
    &::before {
      color:#000;
    }
  }

  & .icon-close {
  font-family: 'icomoon';
  font-size:16px;
  position:absolute;
  top:50%;
  right:10px;
  transform:translateY(-50%);
  color:#5F6267;        
  text-align:center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  &:hover{
    color:#000;
  }   
}

}

.lookup-right{
  &::before {
    left:auto;
    right:15px;
  }
  .form-control {
    padding-left:15px;
    padding-right: 45px;
  }
}

.lookup-focus {
  & .form-control {
    background-color:transparent;
    border-color:transparent;
    width:0;
    padding-left:0;
    padding-right:0;
    height: 36px;
    min-height: 36px;
    &.isLookupExpand {
      width: 100%;
      background-color:#fff;
      border-color:#D9D9D9;
      &.form-control {
        padding-left:15px;
        padding-right: 45px;
      }
      &:focus {
        border-color:#000;
      }
    }
  }
}
  

// Form
.form-check{
  // margin-bottom:20px;
}

.form-group, .form-group-md, .form-group-lg{
  position: relative;
}
.form-group{
  margin-bottom:15px;
}
.form-group-md{
  margin-bottom:20px;
}
.form-group-lg{
  margin-bottom:30px;
}
.asterisk{
  font-size: $font-size-base;
  color:$primary;
  font-weight: $font-weight-semibold;
}

//pagination-flexbox
.pagination-nav {
  display: flex;
  align-items: center;
}
.pagination-count{
  + .pagination{
    margin-left:10px;
  }
}
.pagination{
  margin-bottom:0;
}


//Button
.btn{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .text, .icon{
    display: inline-flex;
    align-items: center;    
  }
  .text{
    + .icon{
      margin-left:10px;
    }
  }
  .icon{
    + .text{
      margin-left:10px;
    }
  }
  &:hover {
    .icon .text {
      transition: .2s ease-in-out;
      color: #F07D12
    }
  }
  &:focus{
      box-shadow: none;
  }
  &:active{
      &:focus{
          box-shadow: none;
      }
  }
}

.btn-icon{
  background-color: transparent;  
  border: none;
  padding: 0;
}


//scrollbar
.scrollbar{
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: #d9d9d9 transparent;
  scrollbar-width: thin;
}

.scrollbar::-webkit-scrollbar {
  width: 6px;
}
.scrollbar::-webkit-scrollbar-thumb {        
  background-color: #d9d9d9;
  border-radius:3px;
  height: 20px;
}


//Helper CSS

.flexbox {
  display: flex;
  justify-content: space-between;
  
}
.divider-line {
  display: inline-flex;
  height: 15px;
  width: 1px;
  background-color: #C5C5C5;
}

//Measurement 

.m-l{margin-left:25px !important;}
.mx-5{margin-left:5px !important; margin-right:5px !important;}
.mb-80{margin-bottom:80px !important;}

.pl-0{padding-left:0 !important;}


//Height - Max Height
.h-30{height: 30px !important;}
.mh-300{max-height: 300px !important;}

.mw-260{min-width:260px !important;}
.mxw-50{max-width:50px !important; width:100% !important;}
.mxw-300{max-width:300px !important; width:100% !important;}

//Font Size
.f-12{font-size:12px !important;}
.f-13{font-size:13px !important;}
.f-14{font-size:14px !important;}
.f-16{font-size:16px !important;}
.f-18{font-size:18px !important;}
.f-20{font-size:20px !important;}
