.notification-container {
  display: flex;
  align-items: center;
  padding: 20px;
  gap: 20px;
  width: 422px;
  height: 74px;
  flex-shrink: 0;

  border-radius: 15px;
  background: #F1F9FF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  position: fixed;
  z-index: 9999;
}

.top-left {
  bottom: 80px;
  left: calc(50vw - 211px);
  transition: transform .6s ease-in-out;
  animation: toast-in-bottom .3s;
}

.notification-message {
  margin-top: 10px;
  color: #000;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;;
}

.notification-close-btn:hover {
  cursor: pointer;
}

@keyframes toast-in-bottom {
  0% {
    bottom: 0px;
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}